import React from "react"
import ProjectCard from "./components/ProjectCard";

import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import SelfPortrait1 from './img/Self-Portrait-Nate-Baldwin.jpg';

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';

function RecentWork() {
  let { path, url } = useRouteMatch();

  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {/* <div className="intro">
        <h1 className="intro_Heading">Nate Baldwin</h1>
        <h4 className="intro_SubHeading">Systems-oriented designer for Adobe's <a href="https://spectrum.adobe.com" target="blank">Spectrum Design System</a></h4>
      </div> */}

      {/* <p className="body4 cta">This portfolio is adaptive and can be customized to meet your specific needs. Use the controls in the menu or visit the style guide to learn more.</p> */}
      <h1>Recent work</h1>
      <p className="body4">No recent work to display. Please visit me on Instagram to see my latest works in progress.</p>


      <div className="projectCardWrapper">
        {/* <ProjectCard 
          title="TEMPORARY"
          image={SelfPortrait1}
          alt="Screenshot of Leonardo web application"
          description="Web tool for creating accessible color palettes for design systems. Built with a sibling javascript module for generating colors in web applications."
        ></ProjectCard> */}

      </div>    

      <Footer />
    </motion.div>
  )
}

export default RecentWork;