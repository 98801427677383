import React from "react"
import { 
  BrowserRouter, 
  Route, 
  Switch,
  useParams,
} from 'react-router-dom'; 

import Menu from './Menu';
import Cross from '../svg/Cross';
import './Navigation.scss';

const Navigation = (props) => {
  const page = useParams().page || 'home';
  const isOpen = props.visible;
  const setter = props.setIsMenuOpen;
  const classNameStyle = (isOpen) ? 'menuWrapper open' : 'menuWrapper';

  return (
    <div className={classNameStyle}>      
      <div className="menu">
        <button className="menuButton" onClick={e => {
          setter(false);
        }}>
          <Cross
            width="18px"
            height="18px"
          />
        </button>

        {/* <Switch>
          <Route exact path='/' component={Menu} />
          <Route path='/:page' component={Menu} />
        </Switch> */}

        <Switch>
          <Route exact path='/'>
            <Menu setIsMenuOpen={setter}/>
          </Route>
          <Route path='/:page'>
            <Menu setIsMenuOpen={setter}/>
          </Route>
        </Switch>
      </div>
      <div id="overlay" onClick={e => {
          setter(false);
        }}></div>
    </div>
    
  );
};

export default Navigation;


