import * as React from "react"

function Cross(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 26.334 26.334"
      width={props.width}
      height={props.height}
      {...props}
      >
      <path d="M24.4,26.517,13.789,15.91,3.182,26.517A1.5,1.5,0,0,1,1.061,24.4L11.668,13.789,1.061,3.182A1.5,1.5,0,0,1,3.182,1.061L13.789,11.668,24.4,1.061a1.5,1.5,0,0,1,2.121,2.121L15.91,13.789,26.517,24.4A1.5,1.5,0,1,1,24.4,26.517Z" transform="translate(-0.622 -0.622)"/>
    </svg>
  )
}

export default Cross