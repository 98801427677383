import React from "react"
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';

function CurriculumVitea(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1>Curriculum vitea</h1>
      <h3>Solo exhibitions</h3>
        <ul>
          <li>2012 Commanding Comprehension, Pirate Contemporary Art Oasis, Denver, CO</li>
          <li>2011 Catharsis, Pirate Contemporary Art Oasis, Denver, CO</li>
          <li>2010 Solo Exhibition, The Fringe Collective, Denver, CO</li>
          <li>2009 Hyperrealism, Next Gallery, Denver, CO</li>
        </ul>

      <h3>Juried exhibitions</h3>
        <ul>
          <li>2012 Faces, Places & Spaces, Arvada Center, Arvada, CO</li>
          <li>2010 Faces of Colorado Salon, Ellie Caulkin's Opera House, Denver, CO</li>
          <li>2010 Bibelot Show, The Kanon Collective, Denver, CO</li>
          <li>2010 Portraits of & by Women, Colorado Women’s Chamber of Commerce, Denver, CO</li>
          <li>2009 Annual Student Show, Rocky Mountain College of Art & Design, Lakewood, CO</li>
          <li>2009 Open Show, Next Gallery, Denver, CO</li>
          <li>2008 Annual Student Show, Rocky Mountain College of Art& Design, Lakewood, CO</li>
          <li>2007 Annual Student Show, Rocky Mountain College of Art & Design, Lakewood, CO</li>
        </ul>

      <h3>Other exhibitions</h3>
        <ul>
          <li>2010 Members Exhibition, Pirate Contemporary Art Oasis, Denver, CO</li>
          <li>2010 Spring Graduation Exhibition, Rocky Mountain College of Art & Design, Lakewood, CO</li>
          <li>2008 Images in Black & White... Plus One, Red Rocks Community College, Lakewood, CO</li>
          <li>2006 Lander Artists' Guild Holiday Show & Sale, Lander Senior Citizens Center, Lander, WY</li>
          <li>2005 Student Art Exhibition, Robert Peck Arts Center, Central Wyoming College, Riverton, WY</li>
        </ul>

      {/* <h3>Awards & Recognition</h3>
        <ul>
          <li>list awards here</li>
        </ul>
         */}

      <h3>Publication & Reviews</h3>
        <ul>
          <li>2012 Westword, Michael Paglia: <a href="https://www.westword.com/arts/standout-shows-at-pirate-and-edge-call-attention-to-the-corner-of-37th-and-navajo-5116036">Standout shows at pirate and edge call attention to the corner of 37th and Navajo</a></li>
          <li>2011 Westword, Michael Paglia: <a href="https://www.westword.com/arts/now-showing-5111675">Now showing (February 15, 2011)</a></li>
        </ul>

      <h3>Memberships & Representation</h3>
        <ul>
          <li>2010 - 2012 Associate Member, Pirate Contemporary Art Oasis, Denver CO</li>
          <li>2009 - 2010 Associate Member, NeXT Art Coop, Denver CO</li>
        </ul>


      <h3>Education</h3>
        <ul>
          <li><strong>2010 BFA Illustration</strong>. Rocky Mountain College of Art &amp; Design (RMCAD)</li>
          <li><strong>2005 Credentials 2D Fine Art</strong>. Central Wyoming College</li>
        </ul>
        

      <Footer/>
    </motion.div>
  )
}
 
export default CurriculumVitea;