import React from "react"
import './Section.scss';

class Section extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <section>
        <h3>{this.props.title}</h3>
        {this.props.children}
      </section>
    )
  }
}
 
export default Section;