import React from "react"
import ProjectCard from "./components/ProjectCard";

import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';

import Diner from './img/Diner-Nate-Baldwin.jpg';
import LipsStudy from './img/Lips-Study-Nate-Baldwin.jpg';
import Repose from './img/Repose-Nate-Baldwin.jpg';
import SelfPortrait from './img/Self-Portrait-Nate-Baldwin.jpg';
import SelfPortrait2 from './img/Self-Portrait-2-Nate-Baldwin.jpg';
import TwoMoons from './img/Two-Moons-Nate-Baldwin.jpg';
// Natalie
// Self portrait at 21

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';

function EarlyWork() {
  let { path, url } = useRouteMatch();

  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {/* <div className="intro">
        <h1 className="intro_Heading">Nate Baldwin</h1>
        <h4 className="intro_SubHeading">Systems-oriented designer for Adobe's <a href="https://spectrum.adobe.com" target="blank">Spectrum Design System</a></h4>
      </div> */}

      {/* <p className="body4 cta">This portfolio is adaptive and can be customized to meet your specific needs. Use the controls in the menu or visit the style guide to learn more.</p> */}
      <hgroup>
        <h1>Early work</h1>
        <h3>2006-2011</h3>
      </hgroup>
      <p className="body4">Early paintings and work that is not part of a series.</p>

      <div className="projectCardWrapper">
        <ProjectCard 
          title="Two Moons"
          image={TwoMoons}
          alt=""
          medium="Acrylic on canvas"
          width="18in"
          height="36in"
          year="2007"
          // description="This is information about the painting itself."
        ></ProjectCard>

        <ProjectCard 
          title="Lips Study"
          image={LipsStudy}
          alt=""
          medium="Acrylic on canvas"
          width="18in"
          height="18in"
          year="2011"
          // description="This is information about the painting itself."
        ></ProjectCard>

        <ProjectCard 
          title="Repose"
          image={Repose}
          alt=""
          medium="Oil on canvas"
          width="48in"
          height="36in"
          year="2009"
          // description="This is information about the painting itself."
        ></ProjectCard>

        <ProjectCard 
          title="Diner"
          image={Diner}
          alt=""
          medium="Oil on canvas"
          width="16in"
          height="34in"
          year="2007"
          // description="This is information about the painting itself."
        ></ProjectCard>

        <ProjectCard 
          title="Self Portrait 1"
          image={SelfPortrait}
          alt=""
          medium="Acrylic on canvas"
          width="36in"
          height="48in"
          year="2007"
          // description="This is information about the painting itself."
        ></ProjectCard>


        <ProjectCard 
          title="Self Portrait 2"
          image={SelfPortrait2}
          alt=""
          medium="Acrylic on canvas"
          width="36in"
          height="48in"
          year="2008"
          // description="This is information about the painting itself."
        ></ProjectCard>

{/* 
        <ProjectCard 
          title="Natalie"
          // image={Natalie}
          alt=""
          medium="Acrylic on canvas"
          width="18in"
          height="36in"
          year="2006"
          // description="This is information about the painting itself."
        ></ProjectCard>

        <ProjectCard 
          title="Self Portrait at 21"
          // image={SelfPortraitAt21}
          alt=""
          medium="Acrylic on canvas"
          width="24in"
          height="36in"
          year="2006"
          // description="This is information about the painting itself."
        ></ProjectCard> */}

      </div>    

      <Footer />
    </motion.div>
  )
}

export default EarlyWork;