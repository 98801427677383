import React from "react"
import Image from "./Image";
import './ProjectCard.scss';

class ProjectCard extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <div className="projectCard">
        <Image src={this.props.image} alt={this.props.alt} className="projectImage"></Image>
        <div className="projectCard_details">
          <h5>"{this.props.title}"</h5>
          <ul className="projectCard_details-list">
            <li>{this.props.medium}</li>
            <li>{this.props.width} x {this.props.height}</li>
            <li>{this.props.year}</li>
          </ul>
          <p>{this.props.description}</p>
        </div>
      </div>
    )
  }
}
 

export default ProjectCard;