import React from "react"
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import Image from './components/Image';
import Section from './components/Section';
import Button from './components/Button';
import Repose from './img/Repose-Nate-Baldwin.jpg';
import RecentWork from "./RecentWork";

function Home(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <Image src={Repose} alt='alt text' className="heroImage"></Image>

      <section className="centered">
        <h1>I'm Nate Baldwin</h1>
        <p className="body--callout">As an artist, I want my work to transport viewers to settings and moments in time that transcend reality.</p>

        <Button href="recent-work">View recent work</Button>

      </section>


      <Footer/>
    </motion.div>
  )
}
 
export default Home;