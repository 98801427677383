import React from "react"
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import Section from './components/Section';
import Image from './components/Image';
import Caption from './components/Caption';
import NateBioImage from './img/Nate-Photographing.jpg';
import GiantScreen from './img/Giant-Screen.jpg';

function Biography(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >

      <div class="splitView">

        <div>
          <h1>Biography</h1>
          <p>Nate Baldwin received a BFA in Illustration from the Rocky Mountain College of Art and Design in 2010. He was a member of Pirate Contemporary Art Oasis in Denver, Colorado and was featured in the Faces of Colorado as an up-and-coming portrait artist.</p>
          <p>Nate's career moved into marketing as a graphic designer and art director. From there Nate gained a passion for systematic design processes, and shifted careers again into user experience design and design systems. Today, Nate is a Senior Experience Designer at Adobe, working on Adobe's design system Spectrum.</p>
          <p>After 10 years of career development, Nate felt the natural pull back to the fine arts. Today, his work is focused more on landscapes where he grew up. For nearly his entire life, Nate has canoed, kayaked, and rafted the major rivers of southwestern Utah: the Green, Colorado, and San Juan. The deep canyons hold a significant personal and spiritual significance to him. Nate strives to embody the natural beauty and wonder of these locations by transporting the viewer into his work.</p>
        </div>

        <Image
            className="circle"
            src={NateBioImage}
            alt=""
          />
      </div>

      <Section title="Reviews and publicity">
        <blockquote>
          "Baldwin is as good as it gets when it comes to applying paint, and it would be no exaggeration to call his work breathtaking"
          <cite>Michael Paglia, Westword</cite>
        </blockquote>

        <blockquote>
          "Commanding Comprehension [...] is made up a single painting — a situation that prompts the question of whether one work is enough to pull off an entire exhibit. Well, when you can paint like Baldwin can, the answer is yes."
          <cite>Michael Paglia, Westword</cite>
        </blockquote>
        
        <Image
          // className="constrained"
          src={GiantScreen}
          alt=""
        />
        <Caption>Faces of Colorado LED Display, Denver, CO</Caption>
      </Section>


      <Footer/>
    </motion.div>
  )
}
 
export default Biography;