import {
  useState,
  useEffect,
  useRef 
  // setState
} from 'react';
import {useTheme} from 'css-vars-hook';
// import * as Leo from '@adobe/leonardo-contrast-colors';
import React from 'react';
import { AnimatePresence, motion, useViewportScroll } from "framer-motion";

import Logo from './svg/LogoMonogram';

import { 
  BrowserRouter, 
  Route, 
  Switch,
  useParams,
  useLocation,
  // useRouteMatch,
  Link 
} from 'react-router-dom'; 

import ScrollToTop from './components/ScrollToTop';
import Navigation from './components/Navigation';
import Accessibility from './svg/Accessibility';
import Popover from './components/Popover';
import Hamburger from './svg/Hamburger';

import RecentWork from './RecentWork.js'; 
import Articles from './Articles.js'; 
import ArtistStatement from './ArtistStatement.js'; 
import CurriculumVitea from './CurriculumVitea.js'; 
import Biography from './Biography.js'; 
import Home from './Home.js'; 
import Catharsis from './Catharsis.js'; 
import EarlyWork from './EarlyWork.js'; 
import Styleguide from './Styleguide.js'; 

import "animate.css";
import './Common.scss';
import './App.scss';


function App(props) {
  const colorSwatches = props.adaptiveTheme.contrastColors;

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const location = useLocation();

  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  const popoverDisplay = (isPopoverOpen) ? 'block' : 'none';

  const [lightness, setLightness] = useState((mq.matches) ? 8 : 100);
  const [contrast, setContrast] = useState(1);
  const [saturation, setSaturation] = useState(100);
  const [sliderMin, setSliderMin] = useState((mq.matches) ? 0 : 80);
  const [sliderMax, setSliderMax] = useState((mq.matches) ? 30 : 100);

  mq.addEventListener('change', function (evt) {
    props.adaptiveTheme.lightness = ((mq.matches) ? 11 : 100)
    setLightness((mq.matches) ? 11 : 100)
    setSliderMin((mq.matches) ? 0 : 80);
    setSliderMax((mq.matches) ? 30 : 100);
  });

  const _createThemeObject = () => {
    let themeObj = {}
    props.adaptiveTheme.contrastColors.forEach(color => {      
      if(color.name) {
        let values = color.values;
        values.forEach(instance => {
          let name = instance.name;
          let val = instance.value;
          themeObj[name] = val;
        });
      } else {
        // must be the background
        let name = 'background'
        let val = color.background;
        themeObj[name] = val;
      }
    })
    return themeObj;
  };

  const theme = useState( _createThemeObject() );

  const {setRef, setVariable} = useTheme(theme);

  function _updateColorVariables() {
    // console.log("Updating color variables");

    let themeInstance = _createThemeObject();    
    for (const [key, value] of Object.entries( themeInstance )) {
      setVariable(key, value);
    }
  };
  _updateColorVariables();


  return (
      <div 
        className="App" 
        ref={setRef}
        >
        <div className="App-header">
          {/* <AnimatePresence initial={false}>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            > */}
              <div className="App-header-content">
                <button 
                  onClick={ e => {setIsMenuOpen(true)}}
                  className="menuButton">
                  <Hamburger 
                    width='24px' 
                    height='24px'/>
                </button>

                <Link to="/" className="logo"  >
                  <Logo />
                </Link>

                <div style={{ width: '44px', display: 'block'}}></div>
              </div>
            {/* </motion.div>
          </AnimatePresence> */}
        </div>
        
        <div className="App-content">
          <AnimatePresence exitBeforeEnter initial={false}> 
          <ScrollToTop />

            <Switch location={location} key={location.pathname}> 
            {/* <Switch> */}
              <Route exact path="/" component={Home} />
              <Route path="/recent-work" component={RecentWork} />
              <Route path="/catharsis" component={Catharsis} />
              <Route path="/early-work" component={EarlyWork} />
              <Route path="/articles" component={Articles} />
              <Route path="/artist-statement" component={ArtistStatement} />
              <Route path="/curriculum-vitea" component={CurriculumVitea} />              
              <Route path="/biography" component={Biography} />
              <Route path="/styleguide">
                {/* <Styleguide></Styleguide> */}
                <Styleguide colors={props.adaptiveTheme.colors} colorSwatches={colorSwatches}/>
              </Route>
            </Switch>
          </AnimatePresence>

        </div>

        <Navigation visible={isMenuOpen} setIsMenuOpen={setIsMenuOpen}/>

      </div>
  );
}

export default App;
