import React from "react"
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import ArticleCard from './components/ArticleCard';

function Articles(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <h1>Articles</h1>
      <p>This is a curated collection of articles I have published. For more articles, visit me on <a href="https://medium.com/@NateBaldwin" target="blank">Medium.com</a>.</p>

      <ArticleCard 
          title="Accessible color for design systems just got easier"
          publisher="Medium.com"
          image="https://miro.medium.com/max/1400/1*BH9WmHe8Ndt7xVpCxEqAzQ.png"
          alt="Squares of color aligned in rows with labels for a design system theme"
          // description="Leonardo has improved the process for creating accessible color themes with better visualizations, automation, and UX improvements."
          link="https://medium.com/@NateBaldwin/accessible-color-for-design-systems-just-got-easier-40e8420a8371"
        ></ArticleCard>

      <ArticleCard 
          title="Color scales for data visualization in Leonardo"
          publisher="Medium.com"
          image="https://miro.medium.com/max/700/1*MvZ-hNAFM5OYEGtujG_Evw.png"
          alt="Gradient from blue to yellow stacked on squares of sample colors from gradient"
          // description="Create sequential, diverging, and qualitative color scales for data visualization in Leonardo."
          link="https://medium.com/@NateBaldwin/color-scales-for-data-visualization-in-leonardo-bf206feb61b9"
        ></ArticleCard>

        <ArticleCard 
          title="Leonardo: an open source contrast-based color generator"
          publisher="Medium.com"
          image="https://miro.medium.com/max/2818/1*rmcnZeNiITkATulEGyMZOA@2x.png"
          alt="Columns of colored squares displaying a spectrum of colors and values with their respective contrast displayed as text"
          description="Create beautiful, accessible, and adaptive color systems using contrast-ratio based generated colors. Leonardo now supports full theme generation."
          link="https://medium.com/@NateBaldwin/leonardo-an-open-source-contrast-based-color-generator-92d61b6521d2"
        ></ArticleCard>

      <ArticleCard 
          title="Creating contrast-based themes with Leonardo"
          publisher="Medium.com"
          image="https://miro.medium.com/max/5636/1*b4nHj0VH62N6DMro8C1pug@2x.png"
          alt="Columns of colored squares displaying a spectrum of colors and values with their respective contrast displayed as text"
          description="A feature enhancement to Leonardo, Adobe’s open-source tool for creating beautiful, accessible, and adaptive color systems using contrast-ratio based generated colors."
          link="https://uxdesign.cc/creating-contrast-based-themes-with-leonardo-32b6219a090f"
        ></ArticleCard>

        <ArticleCard 
          title="Colorimetry and the Cartography of Color"
          publisher="Medium.com"
          image="https://miro.medium.com/max/9341/1*Zd-IUfC39IsR9WYAPGAhEw.jpeg"
          alt="Map with pin and strings emanating from the pin"
          description="An introduction to these topics in relation to user interface design and design systems."
          link="https://medium.com/thinking-design/colorimetry-and-the-cartography-of-color-415ef5315c0a"
        ></ArticleCard>

        <ArticleCard 
          title="Adaptive Color in Design Systems"
          publisher="Medium.com"
          image="https://miro.medium.com/max/2302/0*s6SGNJaFjvdnp7x3.jpg"
          alt="Illustration of spheres with globe-like cross-sections colored by hue and color lightness"
          description="Part one of a three-part series exploring a new approach to creating accessible and perceptually adaptive color palettes in design systems."
          link="https://medium.com/thinking-design/adaptive-color-in-design-systems-7bcd2e664fa0"
        ></ArticleCard>

        <ArticleCard 
          title="Introducing Adaptive Color Palettes"
          publisher="Medium.com"
          image="https://miro.medium.com/max/1150/1*9SLnj32PJmvw_WP3V6mODA.png"
          alt="Illustration of spheres with globe-like cross-sections colored by hue and color lightness"
          description="Part two of a three-part series exploring a new approach to creating accessible and perceptually adaptive color palettes in design systems."
          link="https://medium.com/thinking-design/introducing-adaptive-color-palettes-111b5842fc88"
        ></ArticleCard>

        <ArticleCard 
          title="Adaptive Color in Spectrum, Adobe’s Design System"
          publisher="Medium.com"
          image="https://miro.medium.com/max/1150/1*QhGyZ9TJDFy_pWwnhCcZqA.png"
          alt="Columns of color squares stacked by lightness"
          description="Part three of a three-part series exploring a new approach to creating accessible and perceptually adaptive color palettes in design systems."
          link="https://medium.com/thinking-design/adaptive-color-in-spectrum-adobes-design-system-feeeec89a2c7"
        ></ArticleCard>

        <ArticleCard 
          title="Component and token naming in Design Systems"
          publisher="Medium.com"
          image="https://miro.medium.com/max/1972/1*AkHWF_ZOV7WZx7ex-KO_cg@2x.png"
          alt="Blue button with label Hi my name is... displayed with cursor hovered over button"
          description="There are a lot of stories out about naming, naming conventions, and best practices for design systems. As an author and maintainer of enterprise-level, cross-platform design systems, this is simply my two cents in practical, future-forward, and realistic advice for naming in design systems."
          link="https://medium.com/@NateBaldwin/component-and-token-naming-in-design-systems-366bad54843f"
        ></ArticleCard>

      <Footer />
    </motion.div>
  )
}
 
export default Articles;