import * as React from "react"

function Logo(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2117.1 567.8" {...props}>
      <path d="M1052.5,258c2.3,2.3,2.8,4.7-0.5,6.4c-7.7,4-15.5,7.9-24,12.2c-0.7,1.4-1.5,4.8-3.6,6.6c-8.5,7.3-14.1,16.7-20,25.9
      c-2.1,3.2-2.8,7.3-4.7,12.3c3.4-0.6,5.5-0.7,7.4-1.4c6.8-2.7,13.7-5.2,20.1-8.6c7.6-4,11.4-11.6,16-18.5c3.6-5.5,8.6-10.3,13.3-15
      c3.7-3.8,8.3-4.4,13.3-0.5c0.3-2.9,0.5-5,0.9-8.1c3.4,1.9,6.2,3.4,9.8,5.4c-2.8,4.3-5.6,8.2-8,12.4c-4,6.9-7,14.1-4.6,22.5
      c1.3,4.3,3,7.2,8.1,6.9c8.2-0.4,16.4-0.6,25.1-0.8c-3.3-19.9-2.4-39-0.9-58c0.5-6.1-0.7-11.5-4-17c-6.5-10.7-13.8-20.8-22.9-29.3
      c0,0,0,0,0,0c4.5,0.4,9,0.9,14.2,1.4c0.7-0.8,1.9-2.2,3.8-4.3c4.8,6.2,9.3,12.1,13.9,18c3.3-5.1,6.7-9.6,9.1-14.4
      c2.4-4.8,3.3-10.3,5.6-15.2c2.7-5.6,5.9-11.1,9.5-16.1c1.5-2.1,4.7-3,6.8-4.3c1-3.1,1.4-6.6,3.1-9.3c13.9-22.5,32.2-41.2,52.3-58
      c5.9-5,13.7-7.7,20.6-11.4c2.3,2.5,4,4.4,6,6.7c0.1-2.3,0.2-3.9,0.3-6c2.8-0.3,5.6-0.5,8.8-0.8c0.2,1.7,0.8,3.1,0.4,4.1
      c-1.7,3.7-3.7,7.3-5.9,11.5c-3.1-1.8-6-8.4-10.8-1.4c1.8,0.8,3.1,1.3,5.3,2.2c-4.2,4-8.7,7.3-12,11.5c-3.3,4.2-5.8,8.8-13.2,8.2
      c3,4.8,2.1,9.3-1.6,12.1c-6.5,4.8-8,12.5-11.8,18.8c-6.9,11.3-13.1,23.1-19.1,34.9c-3.7,7.3-6.3,15.1-9.4,22.6
      c-1.6,3.9-3.1,7.8-4.8,11.7c-0.2,0.6-1.3,1-1.2,1.5c1.4,18.4-3.1,36.8-0.5,55.3c0.1,0.9,0.7,1.7,1.3,3.1
      c11.1-4.1,19.3-11.9,27.5-19.5c15.7-14.5,29-30.9,38.2-50.4c3.9-8.3,8.4-16.4,12.8-24.4c0.3-0.6,2.2-0.2,3.3-0.3
      c0,0-0.2-0.2-0.2-0.2c-0.1,2.2-0.2,4.5-0.5,6.7c-0.3,2.4-2.6,4.9,1.1,6.8c0.5,0.3,0.8,1.9,0.6,2.7c-2,6.2-3.5,12.8-6.4,18.6
      c-10.5,20.3-20.7,40.9-35.8,58.3c-6.1,7-12.9,13.4-19.4,20.1c-2.5,2.6-5.1,5-8.3,8.1c6,5.5,11.9,6.3,18.7,4
      c16.6-5.6,31.3-14.8,44.1-26.3c9.2-8.3,18.7-15.8,29.5-21.6c4.3-2.3,7.2-5.2,8.9-10.1c3-8.8,6.3-17.5,10.4-25.8
      c7.1-14.1,14.8-27.9,22.4-41.8c3.8-6.9,7.7-13.8,11.5-20.7c3.3-5.9,3.3-5.9,10.5-6.4c-3.4-5.8,0.1-9.8,3.3-13.9
      c8.6-11,17.2-22,26-32.9c6.8-8.4,15.3-14.8,25-19.5c11.9-5.7,20.3-1.4,20.3,11.6c0,10.5-1.3,21.2-3.2,31.5c-0.6,3.1-5.1,5.4-7.8,8.1
      c0,0,0.3,0.2,0.3,0.2c-5.2-5.3-6.5-11.3-4.5-18.6c1.4-5,1.4-10.4,2.2-16.7c-6.4,1.7-8.8,6.2-11.4,10c-4.2,6.1-7.5,13-11.9,18.9
      c-1.9,2.6-5.8,3.7-9.3,5.8c2.5,4.5,1.7,8.4-2.3,12.8c-3.6,3.9-3.6,10.6-10.1,12.7c-0.8,0.3,0.6,5.6-0.7,6.6c-5,4-5.4,10-7.6,15.2
      c-1.8,4.1-2.9,8.6-4.8,12.7c-0.5,1-2.6,1.3-3.4,1.7c-1.9,7.9-3.7,16.1-5.7,24.3c-0.5,1.9-1.3,3.8-2.4,5.4c-3,4.3-6.6,8-4.8,14.1
      c0.5,1.7-1.4,4.2-3,8.2c2,1.3,5,2.5,6.8,4.6c1.6,1.8,1.9,4.7,3,8c-9.6,1.1-16.9,4.1-15.7,15.6c5.1-0.8,8.1-4.5,11.4-7.4
      c3.4-3.1,6.4-6.7,9.3-10.3c2.9-3.6,6-7.3,7.9-11.4c1.6-3.5,7.2-2.5,7-8.5c-0.2-3.8,4.3-7.8,7-12.2c2.9,2.3-2.1,10.6,6.8,7.1
      c-2.6-5.9-4.7-12,0-17.9c1.5-1.9,1.4-4.9,5.4-4.4c1.4,0.2,3.2-3.5,4.9-5.5l-0.3-0.2c0.7,7.9,0.3,8.5-7.8,12.5
      c0.6,5.3,5.5,2.8,7.5,3.9c-5.9,10.7-12.3,21-17.6,31.8c-7,14.4-17.6,25.6-29.3,36c-3.6,3.2-7,6.7-10.4,10
      c10.5,17.4,45.7,13,54.8-4.2c6.9-12.9,18.2-23.6,27.7-35.1c4.7-5.6,9.8-10.8,14.9-16.1c2.5-2.7,5.4-5.4,9.5-3.2
      c3.3,1.8,4.3,7.1,2.4,11.3c-4.1,9.2-7.2,18.9-15.1,25.9c-1.8,11.3-11.7,20.2-10.5,33.9c10.8-1.8,19.6-5.4,25.4-14.3
      c3.8-5.8,6.7-12.3,10.5-18.1c3.4-5.3,7.1-10.5,11.4-15.1c7-7.6,14.5-14.6,21.9-21.8c1.4-1.4,3-2.6,4.8-3.5c3.6-1.9,7.1-1.7,9.9,1.5
      c2.5,2.8,4.4,5.8,1.9,9.8c-3.2,5.1-6,10.4-9.1,15.5c-5.3,8.8-11.7,17.2-15.6,26.6c-2.5,6-6.9,12.9-2.3,20.7c8.5,1.7,15.3-3,21.6-7.3
      c5.9-4.1,10.2-10.5,15.9-14.9c9.3-7.1,11.8-18.8,19-27.3c1.9-2.2,6.5-3.1,5.7-8.3c-0.5-3.5,2.5-7.4,3.3-11.2
      c1.7-7.8,4.3-14.6,13.6-15.8c0.9-0.1,1.7-1,2.5-1.6c10.1-7.3,11.9-7.1,20.5,2.8c7.4,0,14-3,20.4-6.5c3.8-2.1,7.6-4.1,11.4-6.1
      c4.3-2.3,8.8-3.1,12.8,0.4c6.1,5.2,6.7,7.4,4,14.8c-2.3,6.2-5.1,12.3-7.4,18.5c-4.8,12.8-9.6,25.5-14.1,38.4c-1.1,3-1.2,6.5-1.1,9.8
      c0.1,6.6,4,8.6,10,5.6c1.8-0.9,3.4-2.1,5-3.3c15.1-12,30.3-23.9,45.2-36.1c3.2-2.7,5.8-6.3,8.2-9.8c7.9-11.5,15.3-23.4,23.7-34.6
      c3.5-4.7,8.4-8.7,13.4-11.8c6.9-4.3,13.8-1.6,18,5.5c2.2,3.8,2.6,7,0.1,11.1c-2.8,4.7-4.8,9.9-7.1,14.9c0.6,0.6,1.2,1.1,1.8,1.7
      c6-3.7,12.3-7.2,18.1-11.2c8.2-5.7,15.8-12.3,24.1-17.7c4-2.6,9-4.4,13.8-5.2c9.7-1.7,16.7,6.8,13.6,16.2c-1.5,4.7-4.4,9-5.9,13.7
      c-3.7,11.6-3.8,23.5-2.4,35.5c0.7,6.3,5.2,8.9,10.1,11.2c14.3,6.7,29.6,7.4,44.9,7.4c19.3-0.1,38.6-1,57.8-2
      c20-1,39.4-5.3,58.7-10.8c10.8-3.1,21.7-6.1,32.7-8.7c3.7-0.9,7.8-0.4,12.4-0.5c3.2-8.1,13-5.7,19.3-10.3c5.5-4,12.3-6.3,18.6-9.3
      c0.9-0.4,1.9-1.3,2.7-1.2c8.8,1.3,12.7-6.6,18.9-10.2c6.2-3.6,11.9-8.5,16.9-13.7c13-13.8,16.5-31.5,18.4-49.5
      c0.7-6.9,0.7-14.1-0.6-20.9c-1.7-9.1-4.6-18-7.4-26.8c-2.2-6.9-5.2-13.6-7.8-20.4c-0.2-0.6-0.1-1.5-0.5-1.9
      c-11.1-12-21-25.5-33.6-35.4c-10.1-7.9-23.2-12.3-35.6-16.9c-14.8-5.5-30-9.7-45.2-13.9c-4.9-1.4-4.2-5.1-4.9-8.2
      c-9.9-0.2-19.3-0.3-28.7-0.7c-1.9-0.1-4-0.8-5.5-1.8c-2.1-1.4-3.7-0.6-5.8-0.6c-6.7,0-14.2,0.8-19.6-6c-3.1,1.2-5.6,3.1-10.2,2.5
      c-17.3-2.5-34.9-3.3-52.5-4.5c-6-0.4-11.9-0.2-18.9-0.3c0.1-3.5,0.2-6.8,0.2-10c-0.7-0.1-1.3-0.3-2-0.4c-0.8,3.2-1.6,6.3-2.7,10.6
      c-7.8,0.2-16,0.6-24.3,0.7c-5.1,0.1-7.5-2.8-7.6-8.6c-5,7.3-11.7,7.3-18.7,7.6c-10.9,0.4-21.8,1.9-32.7,3.1
      c-8.2,1-16.4,2.4-25.4,3.7c-1.9-2-4.1-4.4-6.8-7.3c4.8-1.6,8-2.6,11.2-3.7c1.8,0.1,3.6,0.2,7.7,0.4c-3.6-2.3-5.3-3.4-7.5-4.9
      c0.1,2.3,0.1,3.6,0.1,4.9c-2.6-1-5.2-2-6.7-2.5c-0.1-4.7-0.2-8-0.4-11.6c2.5-0.9,6.5-1.3,9-3.4c3.6-2.9,7.1,2,10.8-1.3
      c2.2-2,6.8-1.1,10.1-2c3.8-1,7.7-4,11.1-3.4c7.7,1.2,14.9,1,22.2-1.4c2-0.6,4.2-0.4,6.3-0.7c1.3-0.2,3.4-1.3,3.7-0.9
      c4.5,6,10.8,0.9,16.2,2.4c5.5,1.5,12.1-1,18.9-1.9c1.9,5.9,3.7,11.4,5.5,16.9c0.5-0.1,1-0.2,1.5-0.3c-0.2-5-0.3-9.9-0.5-15
      c4.9,0.8,9.7,3.8,12.7-2.6c0.2-0.5,3.6,0.5,4.7,0.7c4.7,4.4-0.2,10.9,5.5,16.2c-0.6-6.1-1-10.5-1.4-14.9c7.3-0.8,13.9-1.5,21.2-2.3
      c0.5,2.4,1.1,5,2.1,9.1c1.4-3.3,2.2-5.3,3.3-8c6.8,0.3,13.8,0.5,20.9,1c0.4,0,0.6,1.8,0.8,2.2c3-0.7,5.9-2.2,8.7-1.9
      c14.5,1.6,29.3,2.2,43.2,5.9c8.8,2.3,17.5,3,26.2,4.7c2.4,0.5,4.6,2.1,6.9,3.2c-1.7,0.2-3.4,0.4-5.5,0.7c4.6,6.9,3.5-0.7,5.2-0.9
      c7.3,1.6,15,2.3,21.9,5.1c6.7,2.8,13.9,3,20.6,5.5c2.2,0.8,4.6,1.2,6.5,2.3c3.8,2.2,5.9,8.6,11,6.8c4.9-1.8,7.8,3.9,12.4,1.6
      c1.1-0.5,3.5,0.4,4.6,1.4c7.8,7,19,9.3,25.4,18.3c8.7,1.9,11.6,11.1,18.9,14.9c2.6,1.4,4.9,3.6,3.4,7.7c-3.3,1.8-10.5-2.1-10.6,7.7
      c2.7-2.3,4.4-3.7,6.4-5.4c4.8,1.8,9.9,3.7,15.2,5.7c-1.6,2.3-2.4,3.6-3.5,5.2c3,1.8,5.8,3.4,8.9,5.2c-1,2.9-2.1,5.7-3.8,10.5
      c4-1.7,5.7-2.4,8-3.5c4.3,7.6,8.6,15.3,13.2,23.6c-2.9,1-5.2,1.7-7.4,2.4c0,0.5,0,1,0,1.5c2.6,0.4,5.3,0.9,8.1,1.4
      c0.5,0.7,1.4,1.4,1.5,2.3c1.4,7.7,2.2,15.6,4.1,23.2c2.5,9.7-2.3,19.4,0.9,29.3c1.4,4.2-1.7,9.6-1.9,14.9
      c-0.3,6.9-5.2,13.6-6.4,20.6c-1.7,10-6.9,17.9-12.7,25.4c-7.1,9.1-15,17.7-23.1,25.9c-2.2,2.3-6.5,2.6-7.6,3c-4,4.3-6.2,8.1-9.4,9.8
      c-38.7,21.1-79.7,36.4-122.8,45.9c-9.7,2.1-19.3,5.3-29.2,6.6c-40.3,5.1-80.9,7.3-121.4,2.6c-17.4-2-33-9.8-46.9-20.6
      c-11.6-9.1-14.9-21.5-13.7-35.6c0.5-5.9,1.4-11.7,2.3-19.5c-4,2.9-6.8,4.4-9.1,6.6c-12.5,11.9-25,23.9-37.2,36.1
      c-3.5,3.5-6,7.8-9,11.8c-4,5.3-8.2,10.4-11.8,16c-4,6.1-10.7,5.2-16.3,7c-1,0.3-2.6-1.5-3.9-2.2c-3.6-2.1-7.3-4.2-12.1-6.9
      c-0.1-0.5-0.7-2.6-1.3-5c-1.2,0.5-2.4,0.6-3,1.2c-5.1,5.7-10.3,11.3-15.2,17.2c-7,8.1-15.7,10-26,9c-11.3-1.1-21.1-5.6-30.3-11.8
      c-2.6-1.7-5.6-4.5-6.2-7.3c-2.7-12.1-3-23.6,0.2-36.4c4.1-16.3,12.8-30.1,18.7-45.3c-11.7-2.4-12.9-2.1-17.7,7.9
      c-4,8.2-9.4,14.9-16.3,21c-7.1,6.3-15.3,13-18.7,21.4c-7.1,17.6-21.1,28.4-34.4,39.9c-6.9,5.9-15.6,10.2-24.1,13.6
      c-9.5,3.8-18.9,2.6-26.8-5.2c-2.3-2.3-5.3-4-7.3-5.4c-10.3,3.5-19.9,6.9-29.5,10.1c-11.6,3.9-18.2-5.7-27-10.8
      c-28.6,18.9-55.4,13.5-81.1-7.6c-3.9,2.2-7.4,4.9-11.3,6.3c-7.4,2.7-15,6-22.7,6.6c-16.4,1.2-25.9-6.7-29.6-23
      c-4.3,3-8.5,5.4-12.2,8.6c-11.6,9.7-24.8,12.1-39.2,9.2c-1.3-0.3-2.3-1.7-3.5-2.4c-1.4-0.8-3.7-2.5-4.1-2.2c-4,3.6-6.9,0-8.2-2.1
      c-3.4-5.2-7.6-4.8-12.4-3.4c-4.1,1.3-8.2,2.6-12.4,3.9c-1.6,0.5-3.1,1.4-4.7,1.5c-14.4,0.9-29.2,3.5-43.2-0.8
      c-9.7-3-18.9-8.4-22.3-19.5c-0.4-1.2-1.3-2.3-2.1-3.7c-6.1,3.9-11.9,7.8-17.8,11.4c-3.7,2.2-7.5,4.3-11.5,6
      c-12.1,4.9-27.1-2-28.9-14.8c-1-6.7,1.2-14.6,4.3-20.8c4-8.1,7.2-16.9,14.6-23.1c8.6-7.2,16.8-14.9,25.4-22.1
      c3.3-2.8,7.3-3.2,11.8-3.7c6.5-0.7,12.6-4.1,19.6-2.5c0.9,0.2,2.3-2,3.5-3.1c0,0-0.2,0-0.2,0L1052.5,258z M1694.9,36.2
      c-3.8,0.2-7.4,1.5-11.2-1.3c-2.4-1.8-4.1,0.2-3.7,3.3C1685.3,40.4,1690.5,41.3,1694.9,36.2c-0.1-3.7-0.3-7.3-0.4-10.9
      C1691.8,29.9,1691.8,29.9,1694.9,36.2z M1244.7,312.3c-3.6,7.1-6.2,12.1-9,17.7C1246.7,330.6,1247.9,328.3,1244.7,312.3z M1788,26.7
      c-0.7,0.2-1.3,0.3-2,0.5c-0.1,2.2-0.9,4.5-0.2,6.4c0.6,1.6,3,3.8,4.3,3.7c3-0.3,3-3,1.7-5.2C1790.8,30.2,1789.4,28.5,1788,26.7z
      M1911.1,44.5c-1-2-2-3.9-3.5-6.9c-1.7,3.7-5,6-2.2,10.5C1907.5,46.8,1909.2,45.7,1911.1,44.5z M2001.9,82.9c-2.7-7-2.8-6.9-8.8-2.9
      C1996.2,81,1998.9,81.9,2001.9,82.9z M1980,57.9c-0.3,0-0.6-0.1-0.8-0.1c-0.5,3.5-0.9,7.1-1.4,10.6c0.7,0.1,1.3,0.1,2,0.2
      C1979.9,65,1979.9,61.5,1980,57.9z M1948.5,59.4c-2.4-5.1-4-8.3-5.8-12.2C1940.1,52.8,1941.9,55.9,1948.5,59.4z M1955.1,52.6
      c-0.1,2.3-0.2,4.1-0.3,6.3C1959,55.6,1959,55.6,1955.1,52.6z M1768.1,36.8c0.3-0.7,0.7-1.4,1-2.1c-1.5-0.8-3-1.7-4.6-2.4
      c-0.1-0.1-0.9,1.4-1.4,2.2C1764.8,35.3,1766.4,36,1768.1,36.8z M1986.4,56.3c1.1-0.4,2.1-0.7,3.2-1.1c-0.9-1.6-1.8-3.3-2.7-4.9
      c0-0.1-1.6,0.6-1.5,0.7C1985.5,52.8,1986,54.6,1986.4,56.3z M1930,54c-0.5,0.8-0.9,1.5-1.4,2.3c1.5,0.9,3.1,1.8,4.7,2.5
      c0.1,0.1,0.9-1.3,1.3-2.1C1933,55.9,1931.5,55,1930,54z M1319.8,172.9c1.3-1.8,2.4-2.8,2.4-3.7c0-0.8-1.3-1.6-2.1-2.4
      c-0.8,0.9-2,1.6-2.3,2.6C1317.7,170.1,1318.8,171.1,1319.8,172.9z M2013.2,61.1c-0.6,0.2-1.3,0.4-1.9,0.6c0.2,2.8,0.2,5.7,5.6,7.2
      C2015.2,65.5,2014.2,63.3,2013.2,61.1z M1138,338.4c-2,6.1-2,6.1,2.3,6.9C1139.6,343.1,1138.9,341.1,1138,338.4z M1915.3,36.8
      c0.8,0.1,1.5,0.2,2.3,0.3c0.4-1.8,0.8-3.6,1.2-5.4c-1.1-0.1-2.3-0.2-3.4-0.3C1915.4,33.2,1915.3,35,1915.3,36.8z M1991.5,67.6
      c0.8-0.6,1.6-1.3,2.3-1.9c-1.4-1.4-2.7-2.9-4.1-4.3c-0.5,0.4-1,0.8-1.6,1.2C1989.3,64.2,1990.4,65.9,1991.5,67.6z M2004.7,53.8
      c-0.4-1.1-0.8-2.2-1.2-3.3c-2.1,2.7-5.9,1.6-8.9,6.6C1999,55.7,2001.9,54.7,2004.7,53.8z M1994.8,301c0.4-1.1,0.9-2.1,1.1-3.2
      c0-0.2-1.4-0.8-1.5-0.8c-0.7,1-1.3,2-1.9,3C1993.1,300.3,1993.7,300.6,1994.8,301z M1286.3,218.2c0.5,0.4,1,0.8,1.6,1.2
      c0.9-1.1,1.8-2.2,2.7-3.3c-0.3-0.3-0.6-0.6-0.9-0.9C1288.5,216.2,1287.4,217.2,1286.3,218.2z M2063.2,113.6
      c-0.6,0.1-1.2,0.1-1.8,0.2c0.2,1.6,0.3,3.1,0.5,4.7c0.6-0.1,1.6-0.3,1.6-0.4C2063.5,116.6,2063.3,115.1,2063.2,113.6z M1017.3,166.7
      c0.3-0.9,0.6-1.8,1-2.8c-10.5,2.7-16.8-4.3-23.8-9.6c-1.2-0.9-2.4-3.1-3.3-2.9c-8.4,1.9-15.1-3.8-22.9-4.1
      c-1.3-3.6,5.9-7.6-1.6-10.2c-1,2.2-2,4.5-3.4,7.6c-1.4-2.3-2.6-4.4-3.8-6.4c-10.8,5.7-10.8,5.7-17,0c-0.9,1.1-1.9,3.3-2.5,3.2
      c-12-2.3-23.9-2-36-0.5c-3.2,0.4-8.6-2.5-9.5-1.3c-3,4.2-6.8,2.2-10,2.4c-16,0.9-32.1,1.1-48.1,2.2c-7.1,0.5-14.2,2.7-21.2,4.3
      c-20.5,4.4-40.5,10-59.8,18.6c-8,3.6-15.5,8.2-23.7,11.6c-3.7,1.6-8.6,3.1-7.9,8.8c0.8,6,2.1,12.6,8.1,14.8c10.8,4,21.8,7.8,33.6,7
      c14.8-1,29.9,0.4,44.5-3.8c5.3-1.5,16-5.7,21.1-9.4c1.8-1.3,3.7-2.6,5.9-4.2c2.3,2.3,4.3,4.3,6.5,6.6c-3.8,6.2-7.5,12.2-11.1,18.1
      c7.5,3.1,7.5,3.1,12.3-3.8c1.7,0.6,3.5,1.2,5.3,1.8c0.3-0.5,0.8-1.1,0.7-1.4c-2.3-4.9,1.7-8.4,4.5-7.8c7.1,1.5,10.1-4.6,15.8-6.6
      c1.8,2.4,3.7,4.8,5.9,7.6c1.8-11.3,2.3-11.7,16.9-12.1c0.5,1.8,0.6,4.2,1.8,5.8c1.3,1.8,3.6,2.9,4.9,4c2-1.3,3.7-2.4,5.6-3.6
      c3.5,10,3.3,11.7-1,15.8c0.7,1.1,1.2,2.4,2,3.4c0.8,1,2,1.7,3,2.5c-0.9,2.1-1.7,4.1-2.8,6.8c1.7-0.3,3-0.1,3.3-0.6
      c2.1-3.8,5.3-4.4,9.3-4.1c4.2,0.4,8.9-1.2,12.7,0.1c7,2.3,14.3,5,20.2,9.4c8.8,6.6,14.1,16.1,15.3,27.4c0.5,5.3,4.6,10.8-0.5,16
      c-0.3,0.3,0.2,1.7,0.6,2.4c3.7,7.1,1.6,13.3-2.3,19.5c-1.5,2.3-2.5,4.9-3.8,7.4c-1.2-1.2-2.2-2.3-3.3-3.5c-0.5,0.3-1.1,0.6-1.6,0.9
      c1.8,3.1,3.5,6.3,5.6,9.9c-5.7,2.2-5,8.5-7.5,12.9c-12.9,22.4-30.9,38.8-53.7,50.4c-31.3,15.9-63.6,29-98.2,35.7
      c-16.5,3.2-33,3.2-49.6,1.3c-17.9-2.1-35.6-5-52.3-11.9c-20.4-8.5-39.5-19.2-55.9-34.2c-7.8-7.1-13.2-16.2-16.2-26
      c-13.1-42.9-11.2-84.8,10.6-124.7c7.7-14.2,19.2-25.4,30.6-36.5c8.3-8.1,16.5-16.9,26.3-22.9c25.8-15.8,52.8-29.6,82.4-36.9
      c19-4.7,38.3-8.4,58.1-8.3c26.5,0.2,53-0.6,79.4-1c0.3,0,0.6,0.3,1.4,0.7c0.1,2.1,0.1,4.5,0.2,6.8c2.8,1.3,4.4-0.5,6.1-7
      c1.9,0.2,3.8,0.4,7.2,0.8c-2.4,2.1-4,3.4-6.3,5.4c3.1,0.7,5.7,1.3,8.9,2c0.7-2.8,1.3-4.9,1.9-7.3c1.3,0.2,3.3,0.1,3.7,0.8
      c5,8.2,13.4,4.8,20.3,6c4.9,0.8,9.5,2.9,14.5,2.9c0.8,0,2.2,3.1,2.4,4.9c0.2,2.9-0.4,5.9-0.5,8.9c-0.1,4.2,0.2,4.5,6.9,9.7
      c0.7-3.9,0.3-6.8-1-10.3c-0.9-2.5,0.6-5.8,1-8.8c3.3,2.1,5.4,4.4,7.8,4.9c13.6,2.7,11.9,2.4,13.8,14c1.3-2.6,2.4-4.8,4.3-8.9
      c2.6,6.5,4.3,10.7,5.9,15c-2.6,0.1-5.1,0.1-8,0.2c3.2,6.4,5.4,1.5,7.7-0.3c1,1.5,2.1,2.9,3.3,4.6c2.2-5,2.2-5-3.8-12.3
      c5-1.4,7.3,1.8,9.3,5c0.4,0.7-0.1,2.8-0.1,2.8c9.5,1,11.7,11.3,19.5,15c8.1,3.8,15.1,9.8,22.6,14.9c-1.6,4.6-8.5,7.6-2.3,12.3
      l-0.2-0.3c-0.4,1.5-0.8,3-1.4,5.4c-4.1-2-8.7-1.9-7.3-8.5c0.9-4.4-4.7-9.6-7.4-7.3c-5.1,4.6-7.9-0.1-10.9-1.5
      c-3.6-1.7-5.9-5.7-9.3-8.1c-3.4-2.4-7.3-3.9-11-5.8c-0.1-0.1-0.1-0.7,0-0.9C1016.7,167,1017.1,166.9,1017.3,166.7
      c3.8,0.8,7.6,1.9,11.4,2.3c1.3,0.1,2.8-1.6,5-2.9C1027.1,161.3,1022.3,165.7,1017.3,166.7z M900.2,226.8c-0.4-4.8-0.7-9.6-1.1-14.9
      c-9.9-4.3-19.3-0.8-28.7,4.1c-1.8-0.8-3.7-1.7-6.4-2.9c-1.9,10.1-10.5,10.8-17.2,13c-4,1.3-10.5-3.7-10.3,5.5
      c-6.8,1-12.1,8.7-20.2,4.5c-0.3-0.1-1,0.6-2.2,1.4c0.9,1.4,1.9,2.8,2.8,4.1c-4.1,6.9-7.8,9-10.7,6.4c-0.9-2.5-1.8-4.9-2.7-7.3
      c-0.8,5.9,0.5,11,5.9,14.3c-3.5,4.8-6.6,8.9-10.4,14.2c-1.5-4-2.5-6.5-3.9-10.1c-5.1,6.9,1.1,9.8,3,13.3
      c-7.7,12.8-15.2,25.1-22.6,37.6c-4.6,7.8-8.3,16.2-15.9,21.9c-3.6,2.7-9.4,2.8-13-0.3c-2.7-2.3-5.5-4.4-8.6-6.9
      c0.4-8.7,2.5-17.4,7.3-24.4c11.6-17,24.5-33.1,36.8-49.6c1.2-1.6,2-3.4,3.5-5.8c-9.5,0.9-17.7,2.6-25.8,2.3c-8-0.3-15.9-2.6-23.9-4
      c0.4-2,0.8-3.7,1.5-6.7c-4.1,2.5-7,4.3-9.1,5.6c-5.1-2.3-9.2-4.2-13.3-6c1.6-6.7-5.4-6-8-9.1c-4.8-5.7-11.3-10.4-10-19.6
      c-14,10-27.4,31.6-30.3,48.8c-3.7,21.9-3,43.8,3.2,65.2c1.8,6.4,4.3,13.7,8.9,18c12.4,11.6,27.3,19.7,43.6,25.4
      c30.9,10.7,62,13.7,94.1,5.1c22.2-6,43.7-13.6,65.1-22c25.7-10.2,44.9-26.2,55.4-52.5c3.5-8.9,3.7-17.4,2.7-26.3
      c-1.2-10.9-8.4-17.8-19.3-17.3c-8.8,0.4-17.7-1.8-26.6,1.1c-2.3,0.8-5.4-0.9-8.4-1.5c-0.6,0.9-1.7,2.3-2.8,3.8
      c-3.4-3-4.2,1.6-6.3,2.2c-7.3,1.8-14.6,3.3-22,4.8c-1.2,0.2-2.8-0.7-3.6-0.2c-5.4,3.3-10.7,6.9-16.1,10.3c-2.1,1.3-4.5,2.2-6.7,3.3
      c-0.5-0.5-1-1-1.5-1.4c2.5-4,5.1-8.1,7.6-12.1c4.9-7.9,13.6-6.3,20.9-8.8c-2.4-0.4-4.9-0.7-7.3-1.1c-0.1-0.6-0.3-1.2-0.4-1.8
      c6.9-4.2,13.7-8.4,20.1-12.4c3,1.9,5.3,3.2,7.5,4.6c0.3-0.5,0.6-0.9,0.9-1.4c-1.5-1.7-2.9-3.4-5.4-6.2c3.3-0.1,5.3,0.4,6.7-0.4
      c1.4-0.8,2-3.5,3.4-3.9c4.4-1.4,8.4-6.1,13.6-1.8C896,228.1,898.1,227.5,900.2,226.8z M952.7,127.8c-0.2,0.7-0.4,1.4-0.7,2.2
      c0.9,0.9,1.9,2.6,2.6,2.5c5.3-0.6,10.6-1.6,15.8-2.7c0.1,0-0.2-2.6-0.2-2.5C963.7,127.4,958.2,127.6,952.7,127.8z M1048.1,179.9
      c-3.2-2.3-4.1-8.7-10.8-6.2C1039.7,178.5,1043,180.9,1048.1,179.9z M937.3,131.3c4.3-0.5,7.1-0.9,11.9-1.5
      C943.6,125.7,941.2,128.6,937.3,131.3z M923.1,126.3c-0.7-0.3-1.4-0.6-2.1-0.9c-0.8,2.1-1.5,4.2-2.3,6.2c1.2,0.2,2.3,0.5,3.9,0.8
      C922.8,130,922.9,128.1,923.1,126.3z M710.2,213.2c0.8-0.3,1.6-0.7,2.4-1c-0.8-1.9-1.7-3.9-2.5-5.8c-0.7,0.3-1.5,0.6-2.2,0.9
      C708.6,209.3,709.4,211.2,710.2,213.2z M865.3,246.7c-0.5,0.1-1,0.3-1.5,0.4c0.2,1.3,0.4,2.7,0.6,4c0.8-0.3,1.6-0.7,2.4-1
      C866.3,248.9,865.8,247.8,865.3,246.7z M699.6,200.2c0.7-1,1.6-1.7,1.5-2.4c-0.1-0.8-0.9-1.5-1.4-2.3c-0.7,0.8-1.7,1.6-2.1,2.5
      C697.5,198.3,698.7,199.3,699.6,200.2z M826.5,217.4c0.4,3.3,0.7,5.5,1,8.1C832.2,222.1,829.4,220.4,826.5,217.4z M990.7,142.1
      c0.4-0.1,0.7-0.2,1.1-0.3c-0.2-1.4-0.5-2.7-0.7-4.1c-0.8,0.2-1.6,0.5-2.5,0.7C989.3,139.7,990,140.9,990.7,142.1z M997.3,140.9
      c-0.2,0.8-0.4,1.5-0.6,2.3c1.8,0.6,3.6,1.2,5.4,1.7c0.2-0.8,0.5-1.5,0.7-2.3C1001,142.1,999.1,141.5,997.3,140.9z M861.3,133
      c0.4-0.7,0.8-1.4,1.3-2.1c-1.4-0.8-2.7-1.6-4.1-2.4c-0.3,0.5-0.7,1-1,1.6C858.8,131.1,860,132.1,861.3,133z M538,203.5
      c-6.9,4-18,6.7-23.9,6.1c-1.1-0.1-2.4,0.2-3.3,0.8c-4.6,3.5-4.5,3.6-9.7-1.3c-0.4,2.7-0.8,4.7-1.2,7.5c-1.7,0.6-3.8,1.3-5.9,2.2
      c-6.5,2.9-13.3,4.7-19.8,8.3c-9.3,5.1-20.6,6.5-31.1,9.3c-0.8,0.2-2.3-1.9-3.9-3.3c-2.9,8.5-11,6.5-17,7.4c-14.1,2-22.1,7.3-24.9,21
      c-2.8,13.8-4.8,27.8-6.2,41.7c-0.4,3.7,2.5,7.8,4.1,12.4c5.8-0.9,10.6-1.7,14.6-2.3c4.9-9.1,9-17.7,14-25.6
      c5.1-8.1,11-15.6,16.6-23.3c3.3-4.6,7.7-7.9,13.2-9.5c0.5,2.9-5,5.4-0.7,8.8c0.5,0.4,0.1,2.4-0.4,3.4c-1.7,4.3-3.3,8.7-5.6,12.7
      c-1.1,1.9-3.5,3.1-4.3,3.7c-0.5,5.5-0.9,10.3-1.3,15.1c9.1-4.3,22.9-18.5,27.4-27.3c1.8-3.6,2.3-8.7,8-9.1c1.2,1.5,2.5,2.9,3.7,4.4
      c2.9-0.4,4.8,0.7,3.9,3.8c-1.3,4.4-2.9,8.7-4.5,13.2c-3,0.9-5.8,1.7-9.8,2.8c2.2,1.7,3.8,2.9,6.3,4.8c-4.9,8.5-10,17.3-15.8,27.2
      c3.6,0.5,7,1.3,10.4,1.5c14.2,0.8,27.8-1.8,40.8-7.4c11.5-4.9,22.3-10.9,34.7-13.9c4.7-1.1,8.8-5.2,13-8.2c3.8-2.7,7.2-2.7,11.1,2.3
      c0.7-3.8,1.1-6.2,1.6-8.8c10.3-5.8,15.3-7,26.8-6c0,1.7,0,3.5,0,5.3c-0.3,0.8-0.6,1.6-0.9,2.4c2.6,6.1,2.2,11-5.5,12.7
      c-2.4,7.8-7.7,12.4-15.5,14.2c-3.2,9.6-12.6,13-19.6,18.4c-13,9.8-27.1,18-42.7,23.3c-18.2,6.3-36.9,8.1-56,6
      c-10.2-1.1-19.7-4.3-27.9-10.1c-5-3.5-9.1-3.2-13.8-0.9c-8.2,3.9-16.9,6.6-26,6c-4.8-0.3-9.7-1.9-14-4c-3.9-1.9-6.9-5.1-11.5-6.6
      c-3.4-1.1-5.3-6.7-8.6-11.1c-1.6,1.5-3.8,2.9-5,5c-6.7,11.3-16.5,19.8-26.3,28c-7.2,6-15.6,11.8-25.8,4.5c-1.8-1.2-4.3-1.5-6.6-1.9
      c-5.6-1.1-10-3.6-11.8-10.8c-2.9,2-4.9,3.3-6.9,4.7c-5.2,3.6-10.2,7.8-17,7.3c-3.2-0.2-6.5-1.2-9.5-2.4c-4.5-1.7-9-3-14-2.7
      c-1.4,0.1-5.4-1.3-1.6-4.9c0.5-0.4-3.4-5.6-5.3-8.5c1.4-1,3.6-1.9,3.9-3.2c0.4-2-0.7-3.7-3.6-2.4c-0.5,0.2-2.8-1.4-2.7-2.1
      c0.5-5.3,0.9-10.6,2.2-15.7c0.7-2.4,3.4-4.3,5.9-7.1c-3-4.2-0.7-9.1,2.8-12.9c8.6-9.4,16.7-19.7,30.6-21.9c0.6-0.1,1.5-0.4,1.6-0.7
      c2.4-8,10.7-6.8,16-10.2c4.2-2.6,9.8-4.6,14.7-4.3c5.8,0.4,8.7-5.7,14.2-4.5c0.9,0.2,1.9-0.7,2.9-0.7c8.8-0.3,14,6.6,10.2,14.6
      c-6.8,14.2-7.5,29.8-11.3,44.7c-0.1,0.5,0.5,1.2,0.9,2c0.8,0,2.2,0.3,2.6-0.2c11.4-11.6,22.7-23.2,34-35c1.4-1.4,2.5-3.2,3.3-5
      c4.9-10.5,9.5-21.1,14.5-31.6c1.5-3.2,3.9-6,6.7-10.3c-2.9-0.9-5.2-1.9-7.4-2.1c-8.6-1-17.2-1.7-25.7-2.8c-1.9-0.2-5.2-2.1-5.1-2.8
      c0.6-3.6-1.6-3.5-3.9-4.2c-7-2.2-8.4-4.6-7.2-11.9c0-0.3,0.5-0.7,0.4-0.9c-4.6-11.1,4.3-16.8,9.9-23.5c4.9-5.9,14.1-6.3,19.4-0.8
      c3.4,3.4,6.9,4.7,11.5,4.7c14.3,0.2,28.5,3.3,42.8,0.4c1.4-0.3,2.9,0.5,4.6,0.9c-0.5-10.4,6-15.2,19.3-14.5
      c-1.9,3.7-3.7,7.4-6.2,12.1c27.8-0.2,51.6-12.4,78.5-13.8c0.6,0.9,1.9,2.9,4.5,7c0.8-4.3,1.3-6.5,1.8-9.1c-0.1,0.1,0.4-0.6,0.9-0.6
      c13-1.5,26-3.1,39.1-4.2c2.8-0.2,8.1-2,7.7,4.6c0,0.3,4.5,0.9,7,1.4c-1.3,5.4-5.1,6.3-9.2,6.7c2.3-2.4,0.9-3.5-1-4.8
      c-0.7,0.7-1.1,0.9-1.1,1.2c0,1.4,0,2.9,0.1,4.3c0.8-0.3,1.5-0.7,2.3-1c-3.2,3.3-5.2,8.7-11.6,5.9c-0.7-0.3-2.3,1.6-3.5,2.5
      c-2.1,1.4-4.2,2.7-6.2,4c1.7-4.8,1.3-8.9-4-11.2C532,197.5,531.1,202,538,203.5z M301.4,282.5c-17.8,6.8-32.3,17.2-41.3,34.1
      c-1.2,2.3-4.6,3.4-6.2,4.5c1.6,2.2,3.2,4.6,5.4,7.7C275.1,314.1,289.6,299.6,301.4,282.5z M510.9,202.2c6.4,0,4.1-3.7,3.3-6.4
      c-0.2-0.8-1.7-1.2-3.3-2.1C510.9,197.1,510.9,199.5,510.9,202.2z M566.7,314.5c5.1-2.2,5.1-2.2,2.3-7
      C568.2,310,567.6,311.9,566.7,314.5z M445.5,276.9c-1.9-3.4-2.9-5.1-3.9-6.9C438.1,275.7,439.1,277.6,445.5,276.9z M497.3,205
      c2.3-3.3,3.8-5.5,6.4-9.2C497.6,197.1,495.5,199,497.3,205z M580.6,301.2c-0.3-2.4-0.6-4-0.9-6.3c-2.3,1.4-3.8,2.4-6,3.8
      C576.2,299.6,577.9,300.2,580.6,301.2z M467.5,221.9c-0.5-0.5-1-0.9-1.5-1.4c-0.9,0.7-1.7,1.5-2.6,2.2c0.6,0.6,1.2,1.2,1.7,1.8
      C465.9,223.6,466.7,222.7,467.5,221.9z M101.5,518.4c4.3-5.7,3.5-7.7-5-10.4c1.2-4.4,2.6-8.7,3.6-13.2c1.1-4.6,1.8-9.4,2.7-14.1
      c1-5.4,1.5-10.9,3-16.1c0.6-2.1,3.4-3.5,5.3-5.4c-3.8-5.6-3.7-7.4,0.8-13.4c1.1-1.4,2.6-3.4,2.3-4.8c-2-8.1,2-13,9.2-16.9
      c-2.1-3.4-3.1-6.2-0.8-10.4c5.9-10.7,8.8-23.3,15.9-32.9c6.9-9.4,9.2-20.7,16.1-29.9c3.9-5.2,5.9-11.9,9.6-17.2
      c12.9-18.5,25.1-37.6,39.6-54.8c15.9-19,34-36.1,50.9-54.1c2.2-2.3,3.5-5.3,6.5-9.9c-7.2,2.7-12.5,4.7-17.8,6.8
      c-0.4-0.6-0.8-1.2-1.2-1.8c1.1-1.4,2.2-2.8,3.9-5c-2.3,0.3-3.9,0.6-5.6,0.8c0-0.8-0.2-1.8,0-2c7.9-4.3,13.5-13.7,25.2-11.2
      c1.5-8.4,8.4-12,16-14.8c5.5-2.1,10.9-3.5,15.6,1.8c4.7,5.3,6.1,11.3,2.9,17.5c-4.4,8.5-9.2,16.9-14.4,25
      c-12.3,19.3-27.5,36.4-41.6,54.4c-17.5,22.5-33.4,46.4-48.7,70.4c-8.3,13-13.6,28-21.4,41.4c-4.5,7.7-5.4,17.9-14.3,23
      c0.5,5.7-2.6,10.3-4.5,15.3c-2.3,5.8-3.5,12-5.6,17.9c-0.5,1.4-2.8,2.3-4.4,3.4c2.7,7.9-0.6,14.9-3.5,22.5c-2.8-1-5.1-1.9-7.4-2.7
      c-0.3,0.4-0.5,0.8-0.8,1.2c2.5,1.7,5,3.4,8.5,5.9c-1.2,2.1-2.6,4.4-4.7,7.8c0.2,0.8,0.9,3.5,1.7,6.6c-3,0.7-5.3,1.3-9.4,2.3
      c2.8,2.3,4.4,3.6,6,4.8c-0.5,5.5,0.9,12.6-2,16c-4.3,5.1,3.2,10.8-2.6,14.5c-1.3-2.5-2.2-6.4-4.1-7.1c-3.4-1.2-7.5-0.3-11.7-0.3
      c-0.1-0.8-0.2-2.2-0.4-3.7c-4.8-0.8-4.3,2.8-5.2,5.7c2.7,1.7,5,3.2,8.6,5.5c-3.1,1.2-5.1,1.9-7.6,2.9c0.5,2.2,1,4.6,1.5,7.2
      c-1.3,0.5-2.8,1.4-4.4,1.6c-2.6,0.4-5.3,0.3-8.4,0.5c-0.7-1.8-1.3-3.6-1.9-5.4c-1.3,0.2-2.5,0.4-4.3,0.7c0-4.2,0-8.1,0-12.1
      c0.6-0.3,1.3-0.6,1.9-0.9c2,2,3.9,4,5.9,6c1.7-4.5,3.3-8.9,5.3-14.1c-2.4,0.3-5.1,0.7-8.5,1.1c1.5-2.1,2.6-3.9,3.8-5.6L101.5,518.4z
      M126.6,468.9c-0.9-1.1-1.8-2.2-2.9-3.6c-1.4,2-2.4,3.4-4.6,6.5c3.9-1.6,5.8-2.3,7.7-3.1c0.8,4.8,4.2,4.5,7.4,3.4
      c3.3-1.1,3-3.5,1.9-5C132.5,467.7,129.5,468.3,126.6,468.9z M127.9,522.3c3.6-3,4.3-6.5,1.5-8.9c-2.2-1.9-6.1-3-8.8-2.5
      c-1.3,0.3-1.8,5.1-2.6,7.8C122,520.2,124.8,521.2,127.9,522.3z M122.1,501.9c-4.6-1.4-8.7-2.7-13.1-4.1c-1.2,6.6,5.4,4.6,6.8,7.8
      C118.2,504.3,120,503.2,122.1,501.9z M116.8,482.6c0.2,0.6,0.3,1.1,0.5,1.7c2.2-0.8,4.5-1.5,6.7-2.4c0.3-0.1,0.4-1.2,0.2-1.8
      c-0.2-0.5-0.9-0.7-1.5-1.2C120.7,480.1,118.7,481.3,116.8,482.6z M138.8,443.2c-6.4,0.5-6.4,0.5-4,5.9
      C136,447.3,137,445.8,138.8,443.2z M145.2,448.1c0.9,0,1.8,0,2.7-0.1c-0.2-1.7-0.3-3.4-0.5-5.1c-0.6,0-1.2,0-1.8,0
      C145.4,444.7,145.3,446.4,145.2,448.1z M148.7,435.2c-1.4-1.3-2.4-2.5-2.7-2.4c-1,0.5-1.8,1.5-2.7,2.3c0.5,0.5,1.1,1.5,1.6,1.5
      C146,436.5,147,435.8,148.7,435.2z M86.3,147.7c2.4,3.1,4.1,5.2,6.4,7.9c1.2-2.7,1.9-4.3,2.7-5.9c0.9,0.2,1.7,0.1,2,0.4
      c0.8,1.1,1,2.7,1.9,3.4c2.8,2.4,4.8,7.2,2.5,8.3c-4.8,2.4-2.1,12.3-10.5,9.6c-4.5,14.5-12.9,26.1-23.2,37.2c-1,3.3-2.1,7.5-3.7,11.5
      c-1.6,4-4.8,7.6-5.2,11.6c-0.5,4.5,1,9.4,2.4,13.9c0.5,1.7,3,3.4,5,3.9c11.3,3.3,22.7,1.8,33.4-1.8c9.7-3.3,19.4-7.5,28-12.9
      c17.5-10.9,34.2-23,51.3-34.4c13.1-8.7,26.4-17.2,40.8-26.6c2,3.4,3.6,5.9,5.1,8.4c-2,1.2-4.1,2.5-6.5,3.9c-0.5,1.6-0.6,4.6-2.1,6.2
      c-3,3.2-6.8,5.6-10.3,8.4c2.8,6.6-4.1,7.9-6.5,11.6c-2.8,4.4-6.3,8.3-9.4,12.5c-3,4-5.8,8.1-8.7,12.2c-3.1,4.3-6.5,8.4-9.5,12.8
      c-2.7,4-5,8.3-6.3,13.7c7.4-5.2,14.8-10.4,22.3-15.5c1.3,1.8,2.6,3.8,3.9,5.7c1.5,2.1-6.5,5.1-10.8,3.8c-0.6-0.2-1.3-0.2-2.6-0.5
      c-1.6,2.8-3.1,6.1-5.1,9c-1.4,2.1-4.4,3.4-5.2,5.5c-1.6,4.3-7.2-1.6-8.9,4.4c-1,3.6-6.8,5.8-10,9.1c-2.9,3.1-5.1,6.9-7.7,10.4
      c-0.8,1.1-1.8,2-2.7,2.9c-11.7,11.8-20.1,26-29.5,39.6c-7.2,10.5-14.2,21.6-25.7,28.5c-4.5,2.7-7.9,6.3-13.1,0.2
      c-2.5-2.9-8.1-3.2-12.6-4.9c-0.2-4.8-0.5-10-0.7-15.1c10.6-12.6,21-25.5,31.9-38c10.7-12.2,21.8-24,32.4-36.9
      c-2.8,1.3-5.7,2.6-8.5,4c-16.7,8.5-34.5,12.2-53.1,10c-4.4-0.5-8.5-2.1-13,2.2c-1.1-3-1.6-4.7-2.4-6.9c-6,1-12,1-10.6-8.2
      c-3.8-2.6-7.6-5.2-12.9-8.9c-4.6-12.5-1.4-26.2,4.3-39.4c3.1-7.1,8-13.3,9.9-21.1c0.6-2.4,3.9-4.2,7.2-7.5
      c2.4-14.5,16.2-23.1,25.5-35.2c3.9,1.6,7.4,3.1,11.1,4.7c0.2-5.2,0.2-5.2-7.7-7.4C77.3,154.2,81.9,150.9,86.3,147.7z M52.3,187.7
      c3.3,1.6,5.8,0.9,7.7-1.5c0.3-0.4-0.4-1.7-0.6-2.6c-2.3,0.7-4.5,1.5-6.8,2.3C52.5,185.8,52.5,186.4,52.3,187.7z M38.4,223
      c5.6-0.5,5.6-0.5,4.6-5.1C41.3,219.6,39.9,221.2,38.4,223z M65.4,193.7c-0.8,0.8-1.5,1.5-2.3,2.3c2,1.5,4,2.9,6,4.4
      c0.4-0.5,0.9-1,1.3-1.5C68.8,197.2,67.1,195.4,65.4,193.7z M79.7,181.6c-0.8,0.1-1.6,0.1-2.5,0.2c0.5,2.2,1,4.3,1.5,6.5
      c0.4-0.1,0.9-0.1,1.3-0.2C80,185.9,79.8,183.7,79.7,181.6z M69.7,174.9c-0.8-2.1-1.4-3.6-2.5-6.4c-1,2.9-1.6,4.5-2.3,6.4
      C66.7,174.9,67.9,174.9,69.7,174.9z M57.7,218.7c-1.2-0.5-2.3-1.3-2.6-1.1c-0.8,0.6-1.2,1.7-1.7,2.7c0.8,0.2,1.8,0.8,2.5,0.5
      C56.5,220.7,56.9,219.7,57.7,218.7z M1488.2,103.2c-3,0.8-6,1.7-9.5,2.7c-0.5-2.1-1-4.5-1.6-7.4c4.9-1.7,12.3,1.2,12.6-6.5
      c6.4,1.2,12.2,2.3,17.8,3.4c5.6-3.6-3.1-13.9,7.4-14.9c2.6-5.2,6.8-8.5,12-11c5.6-2.6,11.1-6,18-1.3c0.6-11.1,9.9-9.7,15.9-12.5
      c3.6-1.7,8.7,0.1,11.4-4.6c0.1-0.2,3,1.2,5.3,2.1c2.8-6.4,9.8-8.6,16.2-10.7c18.1-6,36.5-11.3,54.8-16.8c1.3-0.4,2.6-0.4,4.3-0.7
      c1.9,2.4,3.9,5,5.9,7.5c-0.7,3.6-1.7,7.4-2.1,11.2c-0.4,3.7-1.8,6.5-5.4,7.5c-7.8,2.3-15.6,4.3-23.7,6.5c-3.4-4-2-6.6,2.2-8.4
      c-3.1-4.9-5.5-3.5-8-0.3c0.7,2.6,1.5,5.3,2.2,8.1c-6.6,2.5-12.6,4.9-18.7,7c-1.1,0.4-3.3,0.1-3.6-0.5c-2.1-4.8-4.5-0.4-5,0.4
      c-3.7,4.9-8.6,6.8-14.5,7.2c-2.3,0.2-4.5,1.1-6.6,2c-7.7,3.1-7.7,3.1-11.2-7.5c0.9,8.6,0.2,14.1-8.4,12.6
      c-10.9,10.2-26.4,10.9-38.5,18.7c-4.6,3-10.5,7.2-14.9,6.2C1497.5,102.2,1492.9,104.3,1488.2,103.2L1488.2,103.2z M1580.1,61.6
      c-2.5,1.6-4.6,3-7.5,4.9c3.2,0.3,5.9,0.6,9.9,1.1C1581.2,64.3,1580.5,62.4,1580.1,61.6z M1518.1,87.2c-0.5-0.1-1.1-0.1-1.6-0.2
      c-0.3,1.5-0.6,3-0.9,4.6c0.8,0.1,1.6,0.3,2.4,0.4C1518.1,90.4,1518.1,88.8,1518.1,87.2z M1669.5,119.3c2.5,5.8,3.4,9.7,9.5,6.7
      c2.8-1.4,6.4-1.1,10.3-1.7c1.3,2.1,2.5,4.3,4.2,7.1c-7.1,2-11,7.2-15.7,14.3c-1.2-4.8-1.8-6.9-2.8-11c-0.7,4.4-1.1,7-1.6,10
      c-12.3,1.8-24.8,3.2-37.2,5.6c-7.2,1.4-11.4-3.5-16.8-5.8c-0.1-3.6-0.2-7.2-0.3-10.4c5.1-6.5,10.9-10.9,18.8-12.6
      c15.3-3.3,30.2-9.9,46.5-5.6C1680.9,124.5,1680.9,124.5,1669.5,119.3z M1664.4,127.4c0.6-0.7,1.1-1.4,1.7-2.1
      c-1.3-1.8-2.7-3.7-4.3-5.9c-2,2.2-3.2,3.6-5.2,5.8C1659.8,126.1,1662.1,126.7,1664.4,127.4z M1488.2,103.2c-0.2,3.9-2,5.8-6.1,5.5
      c-6.3-0.3-12.7-0.4-19.6-0.6c0.2,2,0.4,4.1,0.6,6.1c0,0,0.1-0.1,0.1-0.1c2.2,2.3,3.5,4.7,1.8,7.5c-8.2,4.4-18.2-6.2-25.5,3.5
      c4.1,5.9,4.1,5.9,9.8,2.2c1.3,1.5,2.6,2.9,3.8,4.3c4.1-1.7,8.9-2.6,11.9-5.3c3.1-2.8,4.4-7.5,6.8-11.8c9.1,6.7,14.4-2.9,21.7-4.2
      C1491.5,107.7,1489.9,105.5,1488.2,103.2C1488.2,103.2,1488.2,103.2,1488.2,103.2z M1063.7,194c1.4,0.7,4.1,1.9,4.1,2
      c-2.9,6.8,2.7,10.6,5.3,15.3c0,0,0,0,0,0c-0.6-2.7-1.4-5.4-1.9-8.2c-0.9-4.8,1.4-6.6,7.5-5.7c-0.6,2.4-1.1,4.8-1.7,6.9
      c2.5,1.9,4.5,3.4,6.5,4.9c5.7-4,3.4-7.7,0.1-10.8c-4.3-4-9-7.6-13.5-11.3c-3.8,0.9-6.6,2.5-6.2,6.9
      C1064,194.3,1063.7,194,1063.7,194z M480.4,266.5c3.4-3.4,6.1-7.6,4-12.1c-0.9-2-5.5-2.1-8-3.7c-5.3-3.3-9.9-1.4-14.5,1.3
      c-1.9,1.1-3.6,2.7-5.5,4c0,1.4,0,2.8,0,3.8c5.2-2.5,9.9-4.7,15-7.1c2.1,3.8,3.7,6.6,5.3,9.5C477.9,263.5,479.1,265,480.4,266.5z
      M1427.9,146.8c-0.6,0.5-1.1,1-1.7,1.5c7.7,3.7,0.3,14.4,10.5,18.7c-0.8-5.2-1.3-9-1.9-13.2c2-0.7,4-1.4,4.9-1.8
      c-2-6.1-3.9-11.6-6-18c-1.8,2.1-3.6,4-5.2,5.8c2.3,2.6,3.9,4.5,6.4,7.4C1431.5,147,1429.7,146.9,1427.9,146.8z M1433.4,177.6
      c3.5-1.6,6.1-2.8,10.2-4.7c-6-2-10.6-3.5-15.3-5c-0.6,0.6-1.1,1.2-1.7,1.8c4.2,5.9,4,14.1,10.6,18.2c4.2-1,7.6-1.8,12.4-3
      c-3.3-1.9-5.4-3.1-7.5-4.2c-0.8,1.3-1.5,2.3-2.9,4.7C1436.8,182.2,1435.2,180,1433.4,177.6z M1460.4,203.1
      c-0.1-4.1-0.2-7.1-0.3-10.2c-3.7-0.8-6.1,0.7-5.9,3.7c0.2,2.6,1.4,6.7,3.2,7.4c7.6,3.1,15.4,7.9,25.5,1.6
      C1474.4,204.7,1467.4,203.9,1460.4,203.1z M1059.9,259.5c3.4,0.1,6.8-0.5,10.7-0.8c0.5-7.5-7.7-4.9-9.8-8.4
      c-3.7,2.6-6.7,4.7-9.7,6.8c0.3,0.2,0.6,0.5,0.9,0.8c0,0,0.5,0.2,0.5,0.2C1054.9,258.5,1057.4,259.4,1059.9,259.5z M1243.1,163.3
      c-9.8,4-13.9,10.3-10.2,13.7c2.5-0.1,4.9-0.2,7.9-0.2C1241.5,172.5,1242.2,168.3,1243.1,163.3z M1376.9,156.3
      c-2.2,1.1-4.4,2.1-6.9,3.2c2.1,3.7,3.9,6.8,5.8,10.2c5-4.6,1.7-9.2,0.8-13.7C1376.6,156,1376.9,156.3,1376.9,156.3z M1362.8,198.6
      c0.7,4-4.7,7.9,0.5,13.7c1.7-5.5,3.1-9.4,4.1-13.5c0.7-2.9-1.2-3.3-3.5-2.8C1363.5,196.9,1363.1,197.7,1362.8,198.6z M1438.9,135.6
      c1.7,3.3,3.1,5.9,4.5,8.4c3.2-2.8,5.9-5.2,10.1-8.9C1447.5,135.3,1443.7,135.5,1438.9,135.6z M1350.7,226.9c2-0.5,4-1,5.8-1.4
      c0-6.6-3.6-6.5-8.6-6c1.2,2.9,2.1,5.3,3,7.6C1350.9,227.1,1350.7,226.9,1350.7,226.9z M1243,115.8c0.6,0.1,1.5-3.2,2.5-5.4
      c-4.9,1.5-8.5,2.6-12.1,3.7C1235.2,119.6,1239.7,115.5,1243,115.8z M1364.4,182.7c-1.3,4.4-2.7,8.4-3.6,12.5
      c-0.2,0.9,1.3,2.2,2.1,3.4c0.3-0.9,0.7-1.7,1-2.6C1366.4,191.8,1368.9,187.6,1364.4,182.7z M127.1,553.5c1.9-4.6,6.3-8.2,0.1-12.8
      C127.7,545,119.9,548.4,127.1,553.5z M232,228.3c3.4,0.8,5.8-0.8,9-4.9c-4.2,1.2-6.7,2-9.3,2.8C231.8,226.9,231.9,227.6,232,228.3z
      M200.6,240c-2.1,1.5-4.2,2.9-6.3,4.4c0.4,0.6,0.7,1.2,1.1,1.7c2.2-1.3,4.4-2.7,6.5-4C201.5,241.4,201,240.7,200.6,240z M1497.6,86
      c2.7,2.8,4.8,4.9,6.8,7C1505.3,85.1,1505.3,85.1,1497.6,86z M1228.5,108.3c3.4-0.3,5.5-0.4,7.9-0.6c-0.9-2.6-1.6-4.4-2.9-8.1
      C1231.5,103.1,1230.4,105.1,1228.5,108.3z M1453.6,112.1c0.6,2.4,1,4.1,1.6,6.6c3.2-1.8,5.6-3.2,8-4.5c0,0-0.1,0.1-0.1,0.1
      C1460.2,113.6,1457.3,113,1453.6,112.1z M1241,128.7c0.7-0.5,1.6-0.9,2.2-1.6c1.6-1.8,0.6-4.6-1.2-3.8c-1.3,0.6-2.1,2.6-3,3.9
      C1239.6,127.7,1240.3,128.2,1241,128.7z M1225.6,198.6c2.4-0.5,5.4,2.8,7.4-1.6c-3.6-3.4-5.5-1.1-7.2,1.8
      C1225.8,198.8,1225.6,198.6,1225.6,198.6z M1239.2,152.9c-1.1,1.4-2.2,2.8-3.4,4.3c0.6,0.4,1.2,0.9,1.8,1.3c1.1-1.5,2.1-2.9,3.2-4.4
      C1240.3,153.7,1239.7,153.3,1239.2,152.9z M1050.3,253.7c-1.3,0.9-2.6,1.8-3.9,2.7c0.2,0.5,0.3,1.1,0.5,1.6c1.8-0.1,3.5-0.1,5.3-0.2
      c0,0-0.2,0-0.2,0c-0.3-0.3-0.6-0.6-0.9-0.8C1050.8,255.9,1050.5,254.8,1050.3,253.7z M602.3,280.7c-1.2,0.8-2.3,1.7-3.5,2.5
      c-0.3,0.8-0.6,1.6-0.9,2.4C602,286.9,602.7,284.4,602.3,280.7z M101.5,518.4c-3.9-0.5-6.5-1.9-3.7-6.3c-0.6-0.1-1.2-0.1-2.1-0.2
      c0,2.1,0,4.2,0,6.5c2.2,0,4.1,0,6.1,0C101.7,518.3,101.5,518.4,101.5,518.4z M223.8,224.9c-0.7,0.5-2.1,1.2-2.1,1.6
      c0.2,1,0.9,2.1,1.7,2.8c0.3,0.2,1.5-0.7,2.4-1.2C224.8,226.4,224.3,225.7,223.8,224.9z M900.2,226.8c-1.5-0.9-3-1.7-4.4-2.5
      c-0.6,1.5-1.2,3-1.8,4.5C896,228.1,898.1,227.5,900.2,226.8z"/>
    </svg>
  )
}

export default Logo
