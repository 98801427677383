import * as React from "react"

function Hamburger(props) {
  return (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={props.width}
      height={props.height}
      viewBox="0 0 36 23"
      {...props}
      >
      <path  d="M1.5,23a1.5,1.5,0,0,1,0-3h33a1.5,1.5,0,0,1,0,3Zm0-10a1.5,1.5,0,1,1,0-3h33a1.5,1.5,0,1,1,0,3Zm0-10a1.5,1.5,0,1,1,0-3h33a1.5,1.5,0,1,1,0,3Z"/>
    </svg>
  
  )
}

export default Hamburger