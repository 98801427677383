import * as Leo from '@adobe/leonardo-contrast-colors';

const Theme = () => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  const lightness = (mq.matches) ? 8 : 100;
  const contrast = 1;
  
  const gray = new Leo.BackgroundColor({
    name: 'gray', 
    colorKeys: ['#cacaca'], 
    colorspace: 'RGB', 
    ratios: [-1.12, 1, 1.2, 1.8, 3, 4.5, 6, 10, 15]
  });
  
  const oceanBlue = new Leo.BackgroundColor({
    name: 'oceanBlue',
    colorKeys: ["#52a09d", "#0f475a", "#daece3"],
    colorspace: 'CAM02p',
    ratios: [1.2, 3, 4.5, 6.5, 9.5, 13],
    smooth: true
  })
  
  const burntOrange = new Leo.BackgroundColor({
    name: 'burntOrange',
    colorKeys: ["#a8420f","#640202","#f2af3a"],
    colorspace: 'CAM02p',
    ratios: [1.2, 3, 4.5, 6.5, 9.5, 13],
    smooth: true
  })
  
  const adaptiveTheme = new Leo.Theme({
    colors: [
      gray, 
      oceanBlue,
      burntOrange
    ], 
    backgroundColor: gray, 
    lightness: lightness,
    contrast: contrast,
  });
  
  return adaptiveTheme;
}


export default Theme;