import React from "react"
import ProjectCard from "./components/ProjectCard";

import { BrowserRouter, Route, Link, useParams, useRouteMatch, Switch } from 'react-router-dom'; 
import { AnimatePresence, motion } from "framer-motion";
import Footer from './components/Footer';
import CommandingComprehension from './img/Commanding-Comprehension-Nate-Baldwin.jpg';
import StandardProcedure from './img/Standard-Procedure-Nate-Baldwin.jpg';
import CuringTheDisease from './img/Curing-the-Disease-Nate-Baldwin.jpg';

import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';

function Catharsis() {
  let { path, url } = useRouteMatch();

  const mq = window.matchMedia('(prefers-color-scheme: dark)');

  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      {/* <div className="intro">
        <h1 className="intro_Heading">Nate Baldwin</h1>
        <h4 className="intro_SubHeading">Systems-oriented designer for Adobe's <a href="https://spectrum.adobe.com" target="blank">Spectrum Design System</a></h4>
      </div> */}

      {/* <p className="body4 cta">This portfolio is adaptive and can be customized to meet your specific needs. Use the controls in the menu or visit the style guide to learn more.</p> */}
      <hgroup>
        <h1>Catharsis</h1>
        <h3>2009-2011</h3>
      </hgroup>
      <p className="body4">Disclaimer: Disturbing imagery intended to communicate emotion and meaning beyond literal representation.</p>
      <p className="body4">
        The Catharsis series is an exploration in expressing complex, sometimes overlooked issues regarding mental health and treatment.
      </p>

      <div className="projectCardWrapper">
        <ProjectCard 
          title="Commanding Comprehension"
          image={CommandingComprehension}
          alt=""
          medium="Acrylic on canvas"
          // I think it was 5.5ft x 6ft but I may be wrong
          width="72in"
          height="66in"
          year="2012"
          description="Using Carravagio's 'Incredulity of St. Thomas' as a metaphor for patient comprehension from medical professionals. The patient, having attempted his life, thrusts the finger of the doctor into his wound as if to say, 'feel my wounds and you will know my suffering'. It is a criticism of treatments that do not properly investigate root causes for mental health issues, or disregard the people themselves as simply needing more medication."
        ></ProjectCard>

        <ProjectCard 
          title="Standard Procedure"
          image={StandardProcedure}
          alt=""
          medium="Acrylic on canvas"
          width="102in"
          height="66in"
          year="2010"
          description="This painting signifies the lack of patient control in mental health programs. Often, the patient is presumed to be incompetent and does not have the freedom of choice in treatment options. Conversely, the blindfolded doctor represents medical professionals' who blindly trust medication without regard for patient experiences. Obscured from view, the nurse is a bystander in the near-abusive process."
        ></ProjectCard>

        <ProjectCard 
          title="Curing the Disease"
          image={CuringTheDisease}
          alt=""
          medium="Acrylic on canvas"
          height="102in"
          width="66in"
          year="2011"
          description="Suicide is a very complex and perpetually over-simplified issue. It's taboo and difficult to address, so society chooses to cling to the narrative of sadness. For many, suicide is not an outcome of overwhelming sadness: it's a solution to a problem. The problem in this scenario, is self-hate. The subject loathes himself; seen more as a disease than as a person. Self abuse and self hate culminate to the final act; an act frozen in time by a desperate last minute gaze begging for help."
        ></ProjectCard>

      </div>    

      <Footer />
    </motion.div>
  )
}

export default Catharsis;