import { 
  Link, 
  useParams 
} from 'react-router-dom';
import './Menu.scss';

const MenuLink = ({ page, selected, type, setter }) => {
  const title = page.charAt(0).toUpperCase() + page.slice(1);
  let className = selected ? 'item selected ' : 'item ';
  if(type) className = className + type;
  let linkTo = (page === 'home') ? '' : page.replace(' ', '-');
  
  return (
    <Link 
      to={`/${linkTo}`} 
      className={className} 
      onClick={e => {
        setTimeout(e=> {
          setter(false);
        }, 300);
      }}
    >
      {title}
    </Link>
  );
};

const Menu = (props) => {
  const page = useParams().page || 'home';

  const setter = props.setIsMenuOpen;
  
  return (
    <div className='navigation-sub'>
      <MenuLink setter={setter} type="item--topLevel" page='home' selected={page === 'home' || page === ''} />
      <span className="item--topLevel">Paintings</span>
      <MenuLink setter={setter} page='recent work' selected={page === 'recent-work'} />
      <MenuLink setter={setter} page='catharsis' selected={page === 'catharsis'} />
      <MenuLink setter={setter} page='early work' selected={page === 'early-work'} />
      <span className="item--topLevel">About</span>
      <MenuLink setter={setter} page='artist statement' selected={page === 'artist-statement'} />
      <MenuLink setter={setter} page='biography' selected={page === 'biography'} />
      <MenuLink setter={setter} page='curriculum vitea' selected={page === 'curriculum-vitea'} />
      {/* <MenuLink page='styleguide' selected={page === 'styleguide'} /> */}
    </div>
  );
};

export default Menu;


