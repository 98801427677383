import React from "react"
import {
  pageVariants,
  pageTransition,
  pageStyle
} from './components/Animation';
import { AnimatePresence, motion } from "framer-motion";
import Image from './components/Image';
import Footer from './components/Footer';
import NateDrawing from './img/Nate-Drawing-Process.jpg';

function ArtistStatement(props) {
  return (
    <motion.div
      style={pageStyle}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <div class="splitView splitView--centered">
        <Image
          className="circle"
          src={NateDrawing}
          alt=""
        />
        <h1>Artist statement</h1>
        <p className="body--callout">My work is the product of a tenacious desire to understand human vision and perception. I strive to create illusions that transcend observation or replication; to become enhanced images of our physical world. For me, art is the continual practice of learning about the world around us, improving upon technique, and impressing upon my viewers a sense of wonder for the subject matter they observe.</p>


      </div>

        
      <Footer/>
    </motion.div>
  )
}
 
export default ArtistStatement;