import React from "react"
import { Link } from 'react-router-dom'; 

import './Button.scss';

class Button extends React.Component {
  // constructor(props) {
  //   super(props)
  // }
  render() {
    return (
      <Link 
        to={`/${this.props.href}`} 
        className="button" 
        >
        {this.props.children}
      </Link>
    )
  }
}
 
export default Button;