import React from 'react';
// import {
//   useState, 
//   // setState
// } from 'react';
import ReactDOM from 'react-dom';
import './Common.scss';
import './Slider.scss';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";
import { 
  // BrowserRouter as Router, 
  HashRouter as Router, 
  Route, 
  Switch,
  useParams,
  useLocation,
  // useRouteMatch,
  Link 
} from 'react-router-dom'; 
import Theme from './components/Theme';


ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App adaptiveTheme={Theme()}/>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();