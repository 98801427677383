
// import Behance from '../svg/Behance';
// import Medium from '../svg/Medium';
// import Twitter from '../svg/Twitter';
// import Dribbble from '../svg/Dribbble';
// import Github from '../svg/Github';
// import LinkedIn from '../svg/LinkedIn';
// import Vimeo from '../svg/Vimeo';
import YouTube from '../svg/YouTube';
import Instagram from '../svg/Instagram';

import './Footer.scss';

const Footer = () => {

  return (
    <footer className="App-footer">
      <div className="App-footer-content">
        <div className="socialLinks">
          {/* <a className="socialLink" href="https://www.behance.net/NateBaldwin" target="blank">
            <Behance className="socialIcon"/>
          </a> */}
          <a className="socialLink" href="https://www.instagram.com/nate.baldwin.art/" target="blank">
            <Instagram className="socialIcon"/>
          </a>
          <a className="socialLink" href="https://www.youtube.com/c/NateBaldwinArt" target="blank">
            <YouTube className="socialIcon"/>
          </a>
        </div>
        <p className="App-footer-copyright">All images and content copyright {(new Date().getFullYear())} Nate Baldwin.</p>
      </div>
    </footer>
  );
};

export default Footer;